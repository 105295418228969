<template>
	<div class="mb-3">
		<div class="row">
			<div class="col-12 px-20 status position-relative">
				<div class="p-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
					<div class="page-header">
						<h1 class="page-title">Статус сервисов</h1>
						<table class="table table-striped">
							<thead>
								<tr>
									<th>Сервис</th>
									<th>Статус</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>API</td>
									<th><span class="d-flex border-0 rounded-circle"
											  :class="(this.status.api == null) ? 'bg-warning' : ((this.status.api == true) ? 'bg-success' : 'bg-danger')" style="width:16px;height:16px;">&nbsp;</span></th>
								</tr>
								<tr>
									<td>DataBase</td>
									<th><span class="d-flex border-0 rounded-circle"
											  :class="(this.status.mysql == null) ? 'bg-warning' : ((this.status.mysql == true) ? 'bg-success' : 'bg-danger')" style="width:16px;height:16px;">&nbsp;</span></th>
								</tr>
								<tr>
									<td>Sphinx #1</td>
									<th><span class="d-flex border-0 rounded-circle"
											  :class="(this.status.sphinx == null) ? 'bg-warning' : ((this.status.sphinx == true) ? 'bg-success' : 'bg-danger')" style="width:16px;height:16px;">&nbsp;</span></th>
								</tr>
								<tr>
									<td>Sphinx #2</td>
									<th><span class="d-flex border-0 rounded-circle"
											  :class="(this.status.sphinx2 == null) ? 'bg-warning' : ((this.status.sphinx2 == true) ? 'bg-success' : 'bg-danger')" style="width:16px;height:16px;">&nbsp;</span></th>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div v-if="this.logs.length" class="p-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
					<div class="page-header">
						<h1 class="page-title">Log</h1>
						<div class="table-responsive vh-100">
							<table class="table table-condensed table-striped">
								<thead>
									<tr>
										<th>Дата/время</th>
										<th>Сообщение</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(log, index) in logs"
										:key="index"
										data-bs-toggle="collapse"
										class="accordion-toggle bg-opacity-25"
										:class="{
											'bg-info': log.type == 'info',
											'bg-warning': log.type == 'debug',
											'bg-danger': log.type == 'error',
										}"
										:data-bs-target="'#log_row_' + index">
										<td>{{ log.datetime }}</td>
										<td>
											<div class="fw-bold">{{ log.name }}</div>
											<div v-if="log.is_data" class="accordian-body mt-2 collapse" :id="'log_row_' + index"><pre>{{ this.formatLogMessage(log.message, log.is_data) }}</pre></div>
											<div v-else class="accordian-body mt-2 collapse" :id="'log_row_' + index">{{ this.formatLogMessage(log.message, log.is_data) }}</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<ul class="d-flex flex-column list-unstyled">
					<li><b>Версия:</b> {{ this.version }}<sup v-if="this.version_is_beta" class="text-success">β</sup></li>
					<li><b>Сборка:</b> {{ this.build }}</li>
					<li><b>Окружение:</b> {{ this.environment }}</li>
					<li><b>Релиз:</b> {{ this.formatDateTime(this.release_date) }}</li>
					<li><b>Устройство:</b> {{ this.getDeviceInfo() }}</li>
				</ul>
				<div class="d-flex flex-column mt-4">
					<a href="javascript:{}" @click="this.goBack()" class="d-inline-flex align-items-center ms-0 me-auto">
						<span aria-label="Вернуться назад"
							  class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
							  style="padding: 0.75rem 0.95rem;">
							<img :src="require('@/assets/icons/undo.svg')" alt="Назад" width="18" height="18">
						</span>
						<span class="text-font-light">Назад</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import Device from "../services/Device";
    import CommonService from "../services/CommonService";
	import api from "@/api";
	import moment from "moment";

    export default {
        name: "status",
        data() {
            return {
				title: "Статус сервиса",
				status: {
					api: null,
					mysql: null,
					sphinx: null,
					sphinx2: null,
				},
				version: process.env.VERSION ?? 'v.3.1.1',
				environment: process.env.NODE_ENV,
				version_is_beta: process.env.IS_BETA ?? false,
				build: process.env.BUILD ?? null,
				release_date: process.env.RELEASE_DATE ?? null,
        	}
        },
        methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			goBack(forced) {
				window.history.back();
			},
			getDeviceInfo() {
				let device = Device.init();

				let info = '';
					info += device.os.name + ' (v.' + device.os.version + ')' + ', ';
					info += device.browser.name + ' (v.' + device.browser.version + ')';

				return info;
			},
			formatDateTime(datetime, format) {

				datetime = datetime.replace(' +', '+');
				datetime = datetime.replace(' ', 'T');

				if (typeof format !== 'undefined')
					return CommonService.formatDateTime(datetime, 'ru', format);
				else
					return CommonService.formatDateTime(datetime);

			},
			formatLogMessage(message, is_data) {
				if (is_data || typeof message !== 'string') {
					var seen = [];
					const res = JSON.stringify(message, function (key, val) {
						if (val != null && typeof val === "object") {
							if (seen.indexOf(val) >= 0) {
								return;
							}
							seen.push(val);
						}
						return val;
					});

					return res.toString();
				}

				return message;
			},
			getStatus(service) {
				return api.get('/common/status', {
					params: {
						service: service,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getStatus()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return (response.data.status === 'up');

					return false;
				}).catch(function (error) {

					CommonService.log('error', 'getStatus()::axios', error);

				});
			},
        },
        mounted() {
            CommonService.setPageTitle(this.title);
			this.getStatus('api').then((status) => { this.status.api = status });
			this.getStatus('mysql').then((status) => { this.status.mysql = status });
			this.getStatus('sphinx').then((status) => { this.status.sphinx = status });
			this.getStatus('sphinx2').then((status) => { this.status.sphinx2 = status });
        },
		computed: {
			logs() {
				return this.$store.getters.logs || [];
			},
		},
    };
</script>